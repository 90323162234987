import $http from '@/utitls/request'
/**
 * 获取设备组态分组填充信息
 */
export const GetNodeGroupFillList = (data) => {
    return $http.get('/api/NodeGroup/GetNodeGroupFillList', { params: data })
}
/**
 * 分页获取设备组态分组信息
 */
export const GetNodeGroupPageList = (data) => {
    return $http.get('/api/NodeGroup/GetNodeGroupPageList', { params: data })
}
/**
 * 新增
 */
export const AddNodeGroup = (data) => {
    return $http.post('/api/NodeGroup/AddNodeGroup', data)
}
/**
 * 修改
 */
export const EditNodeGroup = (data) => {
    return $http.put('/api/NodeGroup/EditNodeGroup', data)
}
/**
 * 删除
 */
export const DeleteNodeGroup = (data) => {
    return $http.delete('/api/NodeGroup/DeleteNodeGroup', { params: data })
}