<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="400px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="分组名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="分组序号">
        <el-input-number v-model="form.Sort" :min="0" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { AddNodeGroup, EditNodeGroup } from '@/api/NodeGroup'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  }
})
const formRef = ref(null)
var form = ref({ Sort: 0 })
const dialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '类型名称不能为空', trigger: 'blur' }]
})

const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  if (props.dialogTitle === '修改分组信息') {
    form.value = props.dialogRowValue
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加分组') {
        AddNodeGroup(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditNodeGroup(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
